@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navbar-height: 100px;
  /* Adjust as needed */
}

html[dir="rtl"] .dropdown-menu {
  right: auto;
  /* בטל יישור לימין */
  left: 0;
  /* יישור לשמאל עבור מצב rtl */
}

body {
  text-align: start;
  direction: inherit;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.language-selector {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
}

.flag-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.flag-circle:hover {
  transform: scale(1.1);
}

.flag img {
  width: 100%;
  height: auto;
}

.hero p,
.hero h1,
.hero h2,
.hero h3,
.hero h4,
.hero h5 {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  color: white;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 600px) {
  .flag-circle {
    width: 30px;
    height: 30px;
  }
}