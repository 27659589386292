/* Dropdown menu styling */
.language-selector .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    z-index: 9999;
    padding: 0;
    /* Optional: To remove extra spacing */
}

/* Style for each dropdown item */
.language-selector .dropdown-menu .dropdown-item {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    /* Ensures each item is on its own line */
    padding: 10px 15px;
    /* Adds spacing around each item */
    text-align: left;
    /* Aligns text to the left */
    background-color: white;
    /* Background for each item */
    color: #333;
    /* Text color */
    border-bottom: 1px solid #ddd;
    /* Optional: Adds a separator between items */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;


}

/* Remove border for the last item */
.language-selector .dropdown-menu .dropdown-item:last-child {
    border-bottom: none;
}

/* Hover effect for dropdown items */
.language-selector .dropdown-menu .dropdown-item:hover {
    background-color: #f0f0f0;
    color: #007bff;
}


/* Adjustments for RTL direction */
html[dir="rtl"] .language-selector .dropdown-menu {
    right: auto;
    left: 0;
}

/* Responsive adjustments for dropdown menu */
@media screen and (max-width: 768px) {
    .language-selector .dropdown-menu {
        width: auto;
        left: auto;
        right: 10px;
    }
}

/* Responsive Nav Styling */
nav {
    position: relative;
}

.responsive-nav {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}

.responsive-nav.open {
    transform: translateY(0);
}

.responsive-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.responsive-nav ul li {
    padding: 10px 20px;
    text-align: center;
}

.responsive-nav ul li a {
    text-decoration: none;
    color: #0077b6;
    font-size: 1.2rem;
    transition: color 0.3s;
}

.responsive-nav ul li a:hover {
    color: #023e8a;
}